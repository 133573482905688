body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invisible {
  visibility: hidden;
}

input[type=radio]{
  transform:scale(1.5);
}

input[type=radio]:checked {
  accent-color: #21409A;
}

.visible {
  visibility: visible;
}

.centerAlign {
  text-align: center;
}

.simplewidth { 
  width: 100px !important;
}

dl dt{
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
dl dd{
  display: inline-block;
  margin: 0px 10px;
  padding-bottom: 0;
  vertical-align: middle;
  font-size: 12px;
}
dl dt.lead{
  background: #163c5d;
}
dl dt.honey{
  background: #ffb236;
}
dl dt.steel{
  background: #1f9e6c;
}
dl dt.iron{
  background: #008bae;
}

.required-field::before {
  content: "*";
  color: red;
}