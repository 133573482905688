$theme-colors: (
	"primary": #21409A,
	"success": #1f9e6c, 
	"danger":  #FF0000,
	"alt0": #007789,
	"alt1": #c9ba97,
	"alt2": #362a58,
	"alt3": #bfcd7c,
	"alt4": #4f3344,
	"alt5": #ded58a,
	"alt6": #d0d6da,
	"warning": #ffb236
);

$nav-link-color: #21409A;

$breadcrumb-divider-color:  #21409A;
$breadcrumb-active-color:   #21409A;
$breadcrumb-bg: white;


@import 'bootstrap/scss/bootstrap';


