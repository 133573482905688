.sv_window {
  position: fixed;
  bottom: 3px;
  right: 10px;
  background-color: cadetblue;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
  z-index: 100;
}

.sv_window_title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.sv_window_content {
  padding: 8px;
  margin: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.sv_window_title a,
.sv_window_title a:link,
.sv_window_title a:visited {
  text-decoration: none;
  font-size: 14px;
  font-style: normal;
  color: black;
}

.sv_main.sv_frame .sv_container {
  max-width: 80%;
  margin: auto;
  padding: 0 1em;
}
.sv_main.sv_frame .sv_container .sv_header {
  padding-top: 5em;
  padding-bottom: 1em;
}

.sv_main {
  background-color: #f4f4f4;
}
.sv_main hr {
  border-bottom: 1px solid #e7e7e7;
}
.sv_main input[type=button],
.sv_main button {
  color: white;
  background-color: #1ab394;
}
.sv_main input[type=button]:hover,
.sv_main button:hover {
  background-color: #179d82;
}
.sv_main .sv_q_other input,
.sv_main .sv_q_text_root,
.sv_main .sv_q_dropdown_control,
.sv_main input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.sv_main select,
.sv_main textarea {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #e7e7e7;
  color: #6d7072;
  background-color: white;
  opacity: 1;
}
.sv_main .sv_q_other input:focus,
.sv_main .sv_q_text_root:focus,
.sv_main .sv_q_dropdown_control:focus,
.sv_main input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus,
.sv_main select:focus,
.sv_main textarea:focus {
  border: 1px solid #1ab394;
}
.sv_main .sv_select_wrapper {
  background-color: white;
}
.sv_main .sv_select_wrapper:before {
  background-color: #1ab394;
}
.sv_main .sv_header {
  color: #6d7072;
}
.sv_main .sv_custom_header {
  background-color: #e7e7e7;
}
.sv_main .sv_container {
  color: #6d7072;
}
.sv_main .sv_body {
  background-color: white;
  border-top: 2px solid #1ab394;
}
.sv_main .sv_progress {
  background-color: #e7e7e7;
  margin-bottom: 2em;
}
.sv_main .sv_progress_bar {
  background-color: #1ab394;
}
.sv_main .sv_p_root > .sv_row {
  border-bottom: 1px solid #e7e7e7;
}
.sv_main .sv_p_root > .sv_row:nth-child(odd) {
  background-color: white;
}
.sv_main .sv_p_root > .sv_row:nth-child(even) {
  background-color: #f4f4f4;
}
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  border: 1px solid #ed5565;
  background-color: #fcdfe2;
  color: #ed5565;
}
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + input, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + textarea, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + .sv_select_wrapper select {
  border: 1px solid #ed5565;
}
.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + input:focus, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + textarea:focus, .sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) + .sv_select_wrapper select:focus {
  outline: #ed5565 auto 5px;
}
.sv_main table.sv_q_matrix tr,
.sv_main table.sv_q_matrix_dropdown tr,
.sv_main table.sv_q_matrix_dynamic tr {
  border-bottom: 1px solid #e7e7e7;
}
.sv_main .sv_matrix_cell {
  vertical-align: top;
}
.sv_main .sv_matrix_cell_detail {
  vertical-align: middle;
  text-align: center;
  width: 32px;
}
.sv_main .sv-table__cell--choice {
  text-align: center;
}
.sv_main .sv_matrix_cell_detail_button {
  width: 29px;
  height: 29px;
  min-width: 0px;
  padding: 0;
  outline: none;
}
.sv_main .sv_matrix_cell_detail_button_expanded {
  background-color: lightgray;
}
.sv_main .sv_matrix_cell_detail_button_expanded:hover {
  background-color: darkgray;
}
.sv_main .sv_matrix_cell_detail_rowtext {
  vertical-align: middle;
}
.sv_main .sv_q_m_cell_selected {
  color: white;
  background-color: #179d82;
}
.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: #179d82;
  border-color: #179d82;
  color: white;
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text {
  border: 1px solid #e7e7e7;
}
.sv_main .sv_q_rating_item .sv_q_rating_item_text:hover {
  border: 1px solid #179d82;
}
.sv_main .sv_q_imgsel.checked label > div {
  background-color: #1ab394;
}
.sv_main .sv_q_file_remove:hover {
  color: #1ab394;
}
.sv_main .sv-boolean__switch {
  background-color: #1ab394;
}
.sv_main .sv-boolean__slider {
  background-color: white;
}
.sv_main .sv-boolean__label--disabled {
  color: rgba(64, 64, 64, 0.5);
}

.sv_main {
  position: relative;
  width: 100%;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}
.sv_main hr {
  border: none;
}
.sv_main input[type=button],
.sv_main button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 0.85em;
  font-weight: bold;
  line-height: 2em;
  border: none;
  min-width: 100px;
  cursor: pointer;
  padding: 0 2em;
  border-radius: 2px;
}
.sv_main .sv_custom_header {
  position: absolute;
  width: 100%;
  height: 74%;
  max-height: 275px;
}
.sv_main .sv_container {
  position: relative;
}
.sv_main .sv_container .sv_header {
  padding: 1em;
}
.sv_main .sv_container .sv_header h3 {
  font-size: 2em;
  font-weight: 300;
  margin: 0;
}
.sv_main .sv_container .sv_body {
  padding: 1em 1em 1.6428em 1em;
}
.sv_main .sv_container .sv_body.sv_completed_page {
  text-align: center;
  padding: 5em 1em;
}
.sv_main .sv_container .sv_body.sv_completed_page > h3 {
  margin: 0;
  font-size: 30px;
  font-weight: 300;
}
.sv_main .sv_container .sv_body .sv_progress {
  height: 0.3em;
}
.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar {
  height: 100%;
  min-width: 100px;
  position: relative;
  margin-top: 2em;
}
.sv_main .sv_container .sv_body .sv_progress .sv_progress_bar > span {
  position: absolute;
  top: -1.5em;
  display: inline-block;
  white-space: nowrap;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  font-size: 1em;
  margin-top: 0.1em;
  font-weight: normal;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_p_title {
  font-weight: bold;
  font-size: 1.15em;
  margin: 1.5em 0 0 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_p_panel {
  margin: 0 0 1.5em 0;
}
.sv_main .sv_container .sv_body .sv_p_root > .sv_row {
  padding: 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
  font-weight: bold;
  font-size: 1em;
  margin: 0.5em 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q--disabled .sv_q_title {
  color: rgba(64, 64, 64, 0.5);
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_description {
  margin-top: -0.6em;
  min-height: 0.6em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_flow .sv_q_checkbox_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_q_flow .sv_q_radiogroup_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_q_flow .sv_q_imagepicker_inline {
  line-height: 2em;
  display: inline-block;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q {
  padding: 0.5em 1em 1.5em 1em;
  box-sizing: border-box;
  overflow: auto;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_panel_dynamic .sv_q_title {
  font-weight: normal;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_panel_dynamic .sv-paneldynamic__progress-container {
  position: relative;
  display: inline-block;
  width: calc(100% - 250px);
  margin-left: 40px;
  margin-top: 10px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
  margin: 1em 0;
  padding: 1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.sv_main .sv_container .sv_body .sv_p_root .sv_q select,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 2em;
  padding-left: 1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_text_root:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q select:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q textarea:focus {
  outline: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.sv_main .sv_container .sv_body .sv_p_root .sv_q select {
  height: calc(2em + 1px);
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q div.sv_q_text_root,
.sv_main .sv_container .sv_body .sv_p_root .sv_q div.sv_q_dropdown_control {
  min-height: 2.43em;
  min-width: 7em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select {
  display: block;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2.5em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper select::-ms-expand {
  display: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper:before {
  padding: 1em;
  position: absolute;
  right: 1px;
  right: 0;
  top: 1px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCXZpZXdCb3g9IjAgMCAzNCAzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQgMzQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCgk8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCQkuc3QwIHsNCgkJCWZpbGw6ICNGRkZGRkY7DQoJCX0NCgk8L3N0eWxlPg0KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTIsMTYgMTQsMTQgMTcsMTcgMjAsMTQgMjIsMTYgMTcsMjEgIiAvPg0KPC9zdmc+");
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  pointer-events: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=color] {
  min-height: 2em;
  padding: 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=radio],
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=checkbox] {
  margin: 0;
  margin-right: 0.55em;
  width: 1.2em;
  height: 1.2em;
  vertical-align: middle;
  font-size: 1em;
  margin-top: -0.1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=radio]:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_q input[type=checkbox]:focus {
  outline: 1px dotted #1ab394;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child),
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child) {
  margin-right: 1.5em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imgsel label > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imgsel label > div > img {
  display: block;
  pointer-events: none;
  position: relative;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup_clear {
  margin-top: 0.5em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_checkbox_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_radiogroup_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_imagepicker_inline {
  line-height: 2em;
  display: inline-block;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_footer {
  padding: 1em 0;
}
.sv_main .sv_container .sv_body .sv_p_root table {
  width: 100%;
  border-collapse: collapse;
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix .sv_matrix_dynamic_button, .sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dropdown .sv_matrix_dynamic_button, .sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dynamic .sv_matrix_dynamic_button {
  padding: 0.3em 2em;
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td,
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix th {
  padding: 0.5em 1em;
  text-align: center;
}
@media (min-width: 768px) {
  .sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td {
    min-width: 10em;
  }
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td .sv_q_m_label {
  position: static;
  display: block;
  width: 100%;
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td:first-child {
  text-align: left;
}
.sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix_dropdown .sv_qcbc .sv_q_checkbox_control_label {
  margin-right: 1.5em;
}
.sv_main .sv_container .sv_body .sv_p_root table td,
.sv_main .sv_container .sv_body .sv_p_root table th {
  padding: 0 1em;
}
.sv_main .sv_container .sv_body .sv_p_root table td {
  padding: 0.5em;
}
.sv_main .sv_container .sv_body .sv_p_root table th {
  line-height: 1.3em;
  padding: 0.5em;
  vertical-align: bottom;
  font-weight: bold;
}
.sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc {
  line-height: 2em;
  padding-top: 0.1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_checkbox_label,
.sv_main .sv_container .sv_body .sv_p_root .sv_q_radiogroup_label {
  display: block;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_other {
  margin-left: 1em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_select_column {
  display: inline-block;
  vertical-align: top;
  min-width: 10%;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
  line-height: 2em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item {
  cursor: pointer;
  word-spacing: -0.3em;
  font-weight: normal;
  display: inline;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > * {
  word-spacing: initial;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item > * {
    word-spacing: normal;
  }
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item .sv_q_rating_item_text {
  display: inline-block;
  min-width: 2em;
  padding: 0 0.3em;
  margin-left: -1px;
  text-align: center;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item:not(:nth-child(2)) .sv_q_rating_item_text:not(:hover) {
  border-left-color: transparent;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
  margin-right: 0.5em;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
  margin-left: 0.5em;
}
.sv_main .sv_container .sv_body .sv_nav {
  padding: 1em 0;
  min-height: 2em;
}
.sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  float: right;
}
.sv_main .sv_container .sv_body .sv_nav .sv_preview_btn {
  float: right;
}
.sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  float: left;
}
.sv_main .sv_q_image {
  display: inline-block;
}

.sv_main.sv_main .sv-action-bar-item.sv_edit_btn {
  color: white;
  background-color: #1ab394;
  float: left;
  margin-bottom: 7px;
}

.sv_main.sv_main .sv-action-bar-item.sv_edit_btn:hover {
  background-color: #179d82;
}

.sv_main .sv_p_root .sv_row .sv_q.sv_qstn:first-child:last-child {
  flex: none !important;
}

[dir=rtl] .sv_main input, [style*="direction:rtl"] .sv_main input {
  text-align: right;
}
[dir=rtl] .sv_main th, [style*="direction:rtl"] .sv_main th {
  text-align: right;
}
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn, [style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
[style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
[style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  float: left;
}
[dir=rtl] .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn, [style*="direction:rtl"] .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
  float: right;
}

.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
  display: block;
  width: 100% !important;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .title-left,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .title-left {
  float: none;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_radiogroup_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_checkbox_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_imagepicker_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_radiogroup_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_checkbox_inline,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_imagepicker_inline {
  display: block;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic {
  display: block;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown thead,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic thead {
  display: none;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td.sv-table__cell--choice,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td.sv-table__cell--choice {
  text-align: initial;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tbody,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tr,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td {
  display: block;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td:before,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td:before,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td:before,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td:before {
  content: attr(data-responsive-title);
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td label.sv_q_m_label,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td label.sv_q_m_label {
  display: inline;
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td:after,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td:after {
  content: attr(data-responsive-title);
}
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix .sv_q_m_cell,
.sv_m600 .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix .sv_q_m_cell {
  text-align: initial;
}

@media (max-width: 600px) {
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn {
    display: block;
    width: 100% !important;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .title-left,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .title-left {
    float: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_radiogroup_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_checkbox_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q .sv_q_imagepicker_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_radiogroup_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_checkbox_inline,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_imagepicker_inline {
    display: block;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic {
    display: block;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix thead,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown thead,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic thead,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix thead,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown thead,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic thead {
    display: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td.sv-table__cell--choice,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td.sv-table__cell--choice,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td.sv-table__cell--choice,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td.sv-table__cell--choice,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td.sv-table__cell--choice,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td.sv-table__cell--choice {
    text-align: initial;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tbody,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix tr,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tbody,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown tr,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tbody,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic tr,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tbody,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix tr,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tbody,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown tr,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tbody,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic tr,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td {
    display: block;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dropdown td:before,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix_dynamic td:before,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dropdown td:before,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix_dynamic td:before {
    content: attr(data-responsive-title);
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td label.sv_q_m_label,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td label.sv_q_m_label {
    display: inline;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td:after,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td:after {
    content: attr(data-responsive-title);
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix .sv_q_m_cell,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix .sv_q_m_cell {
    text-align: initial;
  }
}
.sv_main .sv_qstn .sv_q_file .sv-visuallyhidden {
  position: absolute !important;
  opacity: 0;
}
.sv_main .sv_qstn .sv_q_file .sv_q_file_choose_button {
  display: inline-block;
  box-sizing: border-box;
  min-width: 100px;
  line-height: 2em;
  padding: 0.25em 2em;
  font-size: 0.85em;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.sv_main .sv_qstn .sv_q_file .sv_q_file_remove_button {
  padding: 0.25em 2em;
}
.sv_main .sv_qstn .sv_q_file .sv-file__decorator {
  display: inline-block;
  min-width: 250px;
}
.sv_main .sv_qstn .sv_q_file .sv-file__decorator .sv_q_file_placeholder {
  margin-left: 1em;
  display: inline-block;
}

.sv-visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.sjs_sp_container {
  position: relative;
}

.sjs_sp_controls {
  position: absolute;
  left: 0;
  bottom: 0;
}

.sjs_sp_controls > button {
  user-select: none;
}

.sjs_sp_container > div > canvas:focus {
  outline: none;
}

.sjs_sp_placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.sv-boolean__decorator {
  border-radius: 2px;
}

.sv_main .sv-boolean__decorator + .sv-boolean__label {
  float: none;
  vertical-align: top;
  margin-left: 0.5em;
}

.sv-boolean__svg {
  border: none;
  border-radius: 2px;
  background-color: #1ab394;
  fill: white;
  width: 24px;
  height: 24px;
}

.sv-boolean--allowhover:hover .sv-boolean__checked-path {
  display: inline-block;
}
.sv-boolean--allowhover:hover .sv-boolean__svg {
  background-color: #9f9f9f;
  fill: white;
}
.sv-boolean--allowhover:hover .sv-boolean__unchecked-path,
.sv-boolean--allowhover:hover .sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean__checked-path,
.sv-boolean__indeterminate-path {
  display: none;
}

.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #1ab394;
}
.sv-boolean--indeterminate .sv-boolean__indeterminate-path {
  display: inline-block;
}

.sv-boolean--indeterminate .sv-boolean__unchecked-path,
.sv-boolean--checked .sv-boolean__unchecked-path {
  display: none;
}

.sv-boolean--checked .sv-boolean__checked-path {
  display: inline-block;
}

.sv-boolean--disabled.sv-boolean--indeterminate .sv-boolean__svg {
  background-color: inherit;
  fill: #dbdbdb;
}

.sv-boolean--disabled .sv-boolean__svg {
  background-color: #dbdbdb;
}

td.sv_matrix_cell .sv_qbln,
td.td.sv_matrix_cell .sv_qbln {
  text-align: center;
}
td.sv_matrix_cell .sv_qbln .sv-boolean,
td.td.sv_matrix_cell .sv_qbln .sv-boolean {
  text-align: initial;
}

.sv-dragdrop-movedown {
  transform: translate(0, 0);
  animation: svdragdropmovedown 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmovedown {
  0% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.sv-dragdrop-moveup {
  transform: translate(0, 0);
  animation: svdragdropmoveup 0.1s;
  animation-timing-function: ease-in-out;
}

@keyframes svdragdropmoveup {
  0% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}